import jb from './jb.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={jb} className="" alt="logo" />
        <p>
          Coming soon.
        </p>
      </header>
    </div>
  );
}

export default App;
